
import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: "f8eeb5e4-cb88-4571-86a6-72ad144aaf88",
        authority: "https://login.microsoftonline.com/organizations",
        redirectUri: process.env.NODE_ENV==="development" ? "http://localhost:3000" : "https://wiridis.glnet.nu" // "http://localhost:3000" //"" // //
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: any, message: any, containsPii: any) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

export const loginRequest = {
    scopes: ["User.Read", "Sites.Read.All", "Group.Read.All", "User.Read.All"]
};