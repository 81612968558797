import React, { useEffect, useContext, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { UserContext } from '../contexts/UserContext';
import { Button } from 'antd';

export function SyncAppSettings() {}
export interface UserType {
  groups: string[] | undefined;
  userId: string | undefined;
  name: string | undefined;
  UPN: string | undefined;
  GraphToken: string | undefined;
}
export enum userCompanyEnum {
  Wiridis = "Wiridis",
  Markservice = "MarkserviceSthlm"
}

const UserConfig: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { accounts, instance } = useMsal();
  const { user, setUser } = useContext(UserContext);
  const [currentCompany,setCurrentCompany]= useState<string>("");
  const createUserContext = () => {
    try {
      
      const user = instance
        .acquireTokenSilent({
          scopes: ['https://greenlandscapingmalmo.sharepoint.com/.default'],
          account: accounts[0],
        })
        .then(
          (token: any) => {
            const grps = accounts[0].idTokenClaims?.groups as string[];
            setUser({
              groups: accounts[0].idTokenClaims?.groups,
              userId: accounts[0].idTokenClaims?.oid,
              name: accounts[0].name,
              UPN: accounts[0].username,
              GraphToken: token.accessToken,
            });
            sessionStorage.setItem("USERPRINCIPALNAME",accounts[0].username);
            let userCompany: string = userCompanyEnum.Wiridis;
            
            const isWiridis = grps?.some((g) => g === '78d4a33c-eedf-41d7-973d-d1da63a41bfc');
            const isMarkservice = grps?.some((g) => g === 'd83ced50-c944-44f3-9ce5-b50dd78f0ceb');
            if ((isWiridis && !isMarkservice) || (isMarkservice && !isWiridis))
              {
              // Your code here
              console.log("IsWiridis: ",isWiridis, " IsMarkservice: ",isMarkservice)
            
            
              if (
                (accounts[0].idTokenClaims?.groups as string[])?.some(
                  (g) => g === 'd83ced50-c944-44f3-9ce5-b50dd78f0ceb'
                )
              ) {
                userCompany = userCompanyEnum.Markservice;
              }
            sessionStorage.setItem(
              'USERCOMPANY',
              userCompany
            );
            setCurrentCompany(userCompany);
          }
            sessionStorage.setItem('CURRENTUSER', accounts[0].username);
            sessionStorage.setItem("PERSONALNAME", accounts[0].name || "")
          },
          (error: any) => {
            instance.acquireTokenPopup({
              scopes: ['https://greenlandscapingmalmo.sharepoint.com/.default'],
              account: accounts[0],
            });
          }
        );
        instance
          .acquireTokenSilent({
            scopes: ['Group.Read.All', 'GroupMember.Read.All', 'User.Read.All'],
            account: accounts[0],
          })
          .then((token) => {
            sessionStorage.setItem('GRAPHTOKEN', token.accessToken);
          });
    } catch (error) {
      console.error('Error setting user:', error);
    }
  };
  useEffect(() => {
    createUserContext();
  }, []);
  const isMemberOfGroup = (): boolean => {
    if (!accounts[0].idTokenClaims || !accounts[0].idTokenClaims.groups) {
      return false;
    }
    const groups: string[] = accounts[0].idTokenClaims.groups as string[];
    // Now TypeScript knows groups is an array of strings
    return groups.some(
      (group) => group === '78d4a33c-eedf-41d7-973d-d1da63a41bfc' || group === 'd83ced50-c944-44f3-9ce5-b50dd78f0ceb'
    );
  };
  useEffect(() => {}, []);

  const CheckIfWiridis: React.FC = () => {
    useEffect(() => {}, []);
    return isMemberOfGroup() ? (
      <>
        {!currentCompany && (
          <div
            style={{
              width: '100vw',
              height: '100vh',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
            }}>
            {user?.groups?.some((group) => group === 'd83ced50-c944-44f3-9ce5-b50dd78f0ceb') && (
              <Button
                key="Marks"
                onClick={() => {
                  sessionStorage.setItem('USERCOMPANY', userCompanyEnum.Markservice);
                  setCurrentCompany(userCompanyEnum.Markservice);
                }}>
                Markservice Sthlm
              </Button>
            )}
            {user?.groups?.some((group) => group === '78d4a33c-eedf-41d7-973d-d1da63a41bfc') && (
              <Button
                key="Wiridsi"
                onClick={() => {
                  sessionStorage.setItem('USERCOMPANY', userCompanyEnum.Wiridis);
                  setCurrentCompany(userCompanyEnum.Wiridis);
                }}>
                Wiridis
              </Button>
            )}
          </div>
        )}
        {((accounts[0].idTokenClaims?.groups as string[])?.some((g) => g === '78d4a33c-eedf-41d7-973d-d1da63a41bfc') &&
          (accounts[0].idTokenClaims?.groups as string[])?.some(
            (g) => g === 'd83ced50-c944-44f3-9ce5-b50dd78f0ceb'
          )) && (
          <div style={{ width: '100%', textAlign: 'end', position: 'absolute' }}>
            <Button
              onClick={() => {
                sessionStorage.setItem('USERCOMPANY', '');
                setCurrentCompany('');
              }}>
              Välj företag
            </Button>
          </div>
        )}
        {currentCompany !== '' && <div>{children}</div>}
      </>
    ) : (
      <div>
        Hej, <b>{JSON.stringify(accounts[0].name)}</b> du saknar åtkomst till appen då du ej är tillagd som användare.
        kontakta <a href="mailto:andreas.lofkvist@greenlandscaping.se">Andreas Löfkvist</a>
      </div>
    );
  };

  return <CheckIfWiridis />;
};
export default UserConfig;
