import React, { useEffect, useState } from 'react';
import './App.css';
import { isBrowser, isMobile } from 'react-device-detect';
import WebApp from './WebApp';
import { useAppContext } from './contexts/context';
import { getLocalization } from './Languages/LanguageInitializer';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate,useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from "@azure/msal-browser";
import UserConfig from './MsalAuthentication/UserConfig';

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{ outcome: 'accepted' | 'dismissed', platform: string }>;
  prompt(): Promise<void>; 
}
function App() {
  const { language, setLanguage} = useAppContext();
  const [standaloneApp,setStandaloneApp] = useState(false);
  const [installPrompt, setInstallPrompt] = useState<BeforeInstallPromptEvent | null>(null);

  const promptInstall = () => {
    if (installPrompt) {
      installPrompt.prompt(); 
      installPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setInstallPrompt(null); 
      });
    }
  };

  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      instance.loginRedirect();
    }
  }, [isAuthenticated, inProgress, instance]);
  const getLanguage = async () => {
    setLanguage(await getLocalization(navigator.language))
  }
  useEffect(() => {
    // console.log(navigator.language);
    getLanguage()
    if (window.matchMedia('(display-mode:standalone)').matches){
      setStandaloneApp(true);
    }

    const handleBeforeInstallPrompt = (e:any) => {
      
      e.preventDefault();

      setInstallPrompt(e);
    };
  
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  
    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };

  },[])
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <UserConfig>
        <WebApp />
    </UserConfig>
  </AuthenticatedTemplate>
  <UnauthenticatedTemplate>
    <p>{language.notLoggedInMessage}</p>
  </UnauthenticatedTemplate>
    </div>
    
  );
}

export default App;