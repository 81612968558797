import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { ILocalization, defaultLocalization } from '../Languages/LanguageInitializer';
import { SharePointItem } from '../Functions/ApiRequests';

// Define the context state interface
interface AppContextState {
  language: ILocalization;
  setLanguage: React.Dispatch<React.SetStateAction<ILocalization>>;

  allItems: SharePointItem[];
  setAllItems: React.Dispatch<React.SetStateAction<any>>;
  lastUpdate: Date;
  setLastUpdate: React.Dispatch<React.SetStateAction<any>>;
}

const defaultContextState: AppContextState = {
  language: defaultLocalization,
  setLanguage: () => {},
  allItems: [],
  setAllItems: () => {},
  lastUpdate: new Date(Date.now()),
  setLastUpdate: () => {},
};

export const AppContext = createContext<AppContextState>(defaultContextState);

// Custom hook for using context
export const useAppContext = () => useContext(AppContext);

// Define the props for AppContextProvider
interface AppContextProviderProps {
  children: ReactNode;
}

// AppContextProvider component
// AppContextProvider component
export const AppContextProvider: React.FC<AppContextProviderProps> = ({ children }) => {
  const [language, setLanguage] = useState<ILocalization>(defaultLocalization);
  const [lastUpdate, setLastUpdate] = useState<Date>(new Date(Date.now()));
  const [allItems, setAllItems] = useState<SharePointItem[]>([]);

  return (
    <AppContext.Provider value={{ language, setLanguage, allItems, setAllItems, lastUpdate, setLastUpdate }}>
      {children}
    </AppContext.Provider>
  );
};
