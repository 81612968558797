import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useAppContext } from './context';
import { SharePointItem } from '../Functions/ApiRequests';

// Define the context state interface
interface CurrentContextState {
  currentItem: SharePointItem | null;
  setCurrentItem: React.Dispatch<React.SetStateAction<SharePointItem | null>>;
}

const defaultContextState: CurrentContextState = {
  currentItem: null,
  setCurrentItem: () => {},
};

export const CurrentItemContext = createContext<CurrentContextState>(defaultContextState);

// Custom hook for using context
export const useCurrentItemContext = () => useContext(CurrentItemContext);

// Define the props for AppContextProvider
interface AppContextProviderProps {
  children: ReactNode;
}

export const CurrentItemContextProvider: React.FC<AppContextProviderProps> = ({ children }) => {
  const [currentItem, setCurrentItem] = useState<SharePointItem | null>(null);
  const { allItems } = useAppContext();
  useEffect(() => {
    if (!currentItem?.Avvikelsetyp) {
      if (!allItems.some((item) => currentItem?.ID === item.ID)) {
        if (allItems[0] !== undefined) {
          allItems && setCurrentItem(allItems[0]);
        }
      }
    }
  }, [allItems]);
  useEffect(() => {
    console.log('Current Item in Context: ', currentItem);
  }, [currentItem]);
  return <CurrentItemContext.Provider value={{ currentItem, setCurrentItem }}>{children}</CurrentItemContext.Provider>;
};
