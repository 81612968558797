import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { SharePointItem } from '../Functions/ApiRequests';
import { _DeepPartialObject } from 'chart.js/dist/types/utils';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const StatusInfo = ({ data }: {data:SharePointItem[]}) => {
    const [chartData, setChartData] = useState<any>();
    const colorMapping: { [key: string]: string } = {
      Insåld: 'rgba(75, 192, 192, 0.2)',
      Skapad: 'rgba(153, 102, 255, 0.2)',
      "Kontakta kund": 'rgba(159, 159, 64, 0.2)',
      "On hold": 'rgba(130,255,42,0.2)',
      "Avslutad": "rgba(200,30,45,0.2)"
    };
    useEffect(() => {
        const statusCounts = data.reduce((acc: any, item) => {
          acc[item.Status] = (acc[item.Status] || 0) + 1;
          return acc;
        }, {});

        const chartD = {
          labels: Object.keys(statusCounts).map((key) => `${key} (${statusCounts[key]})`),
          datasets: [
            {
              label: 'Status Antal',
              data: Object.values(statusCounts),
              backgroundColor: Object.keys(statusCounts).map((label) => colorMapping[label.split(' ')[0]] || 'rgba(255, 99, 132, 0.2)'),
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
            },
          ],
        };
        setChartData(chartD);
    },[JSON.stringify(data)])
  

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as
          | 'top'
          | 'center'
          | 'left'
          | 'right'
          | 'bottom'
          | 'chartArea'
          | _DeepPartialObject<{ [scaleId: string]: number }>
          | undefined,
      },
      title: {
        display: true,
        text: 'Fördelning Status',
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
        
        max:data.length,
        ticks: {
            min: data.length,

          stepSize: 0.1, // Ensures each step on y-axis is at least 1 unit
        },
      },
    },
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center'}}>
      <div style={{ width: '80%', maxWidth: '600px'}}>
        {chartData && <Bar data={chartData} options={options} />}
      </div>
    </div>
  );
};

const Avvikelsetyp = ({ data }: { data: SharePointItem[] }) => {
  const [chartData, setChartData] = useState<any>();
  const colorMapping: { [key: string]: string } = {
    "Vandalisering": 'rgba(75, 192, 192, 0.2)',
    Förbättringsförslag: 'rgba(153, 102, 255, 0.2)',
    Övrigt: "rgba(20,20,20,0.2)",
    Inventering: 'rgba(40, 39, 200, 0.2)',
    Markrevision : 'rgba(130,200,42,0.2)',
    Akut: 'rgba(200,30,45,0.2)',
  };
  useEffect(() => {
    const statusCounts = data.reduce((acc: any, item) => {
      acc[item.Avvikelsetyp] = (acc[item.Avvikelsetyp] || 0) + 1;
      return acc;
    }, {});

    const chartD = {
      labels: Object.keys(statusCounts).map((key) => `${key} (${statusCounts[key]})`),
      datasets: [
        {
          label: 'Avvikelsetyp Antal',
          data: Object.values(statusCounts),
          backgroundColor: Object.keys(statusCounts).map(
            (label) => colorMapping[label.split(' ')[0]] || 'rgba(255, 99, 132, 0.2)'
          ),
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    };
    setChartData(chartD);
  }, [JSON.stringify(data)]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as
          | 'top'
          | 'center'
          | 'left'
          | 'right'
          | 'bottom'
          | 'chartArea'
          | _DeepPartialObject<{ [scaleId: string]: number }>
          | undefined,
      },
      title: {
        display: true,
        text: 'Fördelning Avvikelsetyp',
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        max: data.length,
        beginAtZero: true,
        ticks: {
          stepSize: 0.1, // Ensures each step on y-axis is at least 1 unit
        },
        
      },
    },
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ width: '80%', maxWidth: '600px' }}>{chartData && <Bar data={chartData} options={options} />}</div>
    </div>
  );
};

const RapporteradAv = ({ data }: { data: SharePointItem[] }) => {
  const [chartData, setChartData] = useState<any>();
  const colorMapping: { [key: string]: string } = {
    Vandalisering: 'rgba(75, 192, 192, 0.2)',
    Förbättringsförslag: 'rgba(153, 102, 255, 0.2)',
    Övrigt: 'rgba(20,20,20,0.2)',
    Inventering: 'rgba(40, 39, 200, 0.2)',
    Markrevision: 'rgba(130,200,42,0.2)',
    Akut: 'rgba(200,30,45,0.2)',
  };
  useEffect(() => {
    const statusCounts = data.reduce((acc: any, item) => {
      acc[item.Namn] = (acc[item.Namn] || 0) + 1;
      return acc;
    }, {});

    const chartD = {
      labels: Object.keys(statusCounts).map((key) => `${key} (${statusCounts[key]})`),
      datasets: [
        {
          label: 'Rapporterade ÄTA-arbeten per användare',
          data: Object.values(statusCounts),
          backgroundColor: Object.keys(statusCounts).map(
            (label) => colorMapping[label.split(' ')[0]] || 'rgba(255, 99, 132, 0.2)'
          ),
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    };
    setChartData(chartD);
  }, [JSON.stringify(data)]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as
          | 'top'
          | 'center'
          | 'left'
          | 'right'
          | 'bottom'
          | 'chartArea'
          | _DeepPartialObject<{ [scaleId: string]: number }>
          | undefined,
      },
      title: {
        display: true,
        text: 'ÄTA-arbeten per användare',
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        max: data.length,
        beginAtZero: true,
        ticks: {
          stepSize: 0.1, // Ensures each step on y-axis is at least 1 unit
          suggestedMax: data.length
        },
      },
    },
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ width: '80%', maxWidth: '600px' }}>{chartData && <Bar data={chartData} options={options} />}</div>
    </div>
  );
};

const AvslutadKategori = ({ data }: { data: SharePointItem[] }) => {
  const [chartData, setChartData] = useState<any>();
  const colorMapping: { [key: string]: string } = {
    "Ingår, skickat till personal": 'rgba(75, 192, 192, 0.2)',
    "Beställt, har skapat AO": 'rgba(153, 102, 255, 0.2)',
    "Kund vill inte ha": "rgba(20,20,20,0.2)",
    "Anmälan finns redan": 'rgba(40, 39, 200, 0.2)',
    "Övrigt" : 'rgba(130,200,42,0.2)',
    "Ej angiven" : 'rgba(200,30,45,0.2)'
  };
  useEffect(() => {
    const statusCounts = data.reduce((acc: any, item) => {
      acc[item.Avslutadanledning] = (acc[item.Avslutadanledning] || 0) + 1;
      return acc;
    }, {});

    const chartD = {
      labels: Object.keys(statusCounts).map((key) => {if (key ==="null") key="Ej angiven"; return `${key} (${statusCounts[key]})`}),
      datasets: [
        {
          label: 'Avslutskategori antal',
          data: Object.values(statusCounts),
          backgroundColor: Object.keys(statusCounts).map(
            (label) => colorMapping[label.split(' ')[0]] || 'rgba(255, 99, 132, 0.2)'
          ),
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1
        },
      ],
    };
    setChartData(chartD);
  }, [JSON.stringify(data)]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as
          | 'top'
          | 'center'
          | 'left'
          | 'right'
          | 'bottom'
          | 'chartArea'
          | _DeepPartialObject<{ [scaleId: string]: number }>
          | undefined,
      },
      title: {
        display: true,
        text: 'Fördelning Avslutskategori',
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        max: data.length,
        beginAtZero: true,
        ticks: {
          stepSize: 0.1, // Ensures each step on y-axis is at least 1 unit
        },
        
      },
    },
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ width: '80%', maxWidth: '600px' }}>{chartData && <Bar data={chartData} options={options} />}</div>
    </div>
  );
};

const Pris = ({ data }: { data: SharePointItem[] }) => {
  const [chartData, setChartData] = useState<any>();
  
  useEffect(() => {
    const namesAndPris = data.reduce((acc: any, item) => {
      if (item.UserPrincipalName){
        if (item.Godk_x00e4_ntpris)
        acc[item.Namn]  = acc[item.Namn] || 0 +  Number(item.Godk_x00e4_ntpris) || 0;
      }
      return acc;
    }, {});

    const chartD = {
      labels: Object.keys(namesAndPris).map((key) => {if (key ==="null") key="Ej angiven"; return `${key} (${namesAndPris[key]})`}),
      datasets: [
        {
          label: 'Försäljning per användare',
          data: Object.values(namesAndPris),
          // backgroundColor: Object.keys(namesAndPris).map(
          //   (label) => 
          // ),
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1
        },
      ],
    };
    setChartData(chartD);
  }, [JSON.stringify(data)]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as
          | 'top'
          | 'center'
          | 'left'
          | 'right'
          | 'bottom'
          | 'chartArea'
          | _DeepPartialObject<{ [scaleId: string]: number }>
          | undefined,
      },
      title: {
        display: true,
        text: 'Ackumulerat pris per användare',
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        max: data.length,
        beginAtZero: true,
        ticks: {
          stepSize: 0.1, // Ensures each step on y-axis is at least 1 unit
        },
        
      },
    },
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ width: '80%', maxWidth: '600px' }}>{chartData && <Bar data={chartData} options={options} />}</div>
    </div>
  );
};

const Nyförsäljning = ({ data }: { data: SharePointItem[] }) => {
  const [chartData, setChartData] = useState<any>();
  
  const colorMapping: { [key: string]: string } = {
    "Henrik.Magnusson@greenlandscaping.se": 'rgba(195, 50, 50, 0.2)',
    "Mutaz.Nafel@greenlandscaping.se": 'rgba(153, 102, 255, 0.2)'
  };
  useEffect(() => {
    const namesAndPris = data.reduce((acc: any, item) => {
      const username =
        item.UserPrincipalName === "Mutaz.Nafel@greenlandscaping.se"
          ? "Mutaz Nafel"
          : item.UserPrincipalName === "Henrik.Magnusson@greenlandscaping.se"
          ? "Henrik Magnusson"
          : null;

      if (username) {
        if (item.Godk_x00e4_ntpris)
        acc[username || "Okänd"]  = acc[username] || 0 +  Number(item.Godk_x00e4_ntpris) || 0;
      }
      return acc;
    }, {});

    const chartD = {
      labels: Object.keys(namesAndPris).map((key) => {if (key ==="null") key="Ej angiven"; return `${key} (${namesAndPris[key]})`}),
      datasets: [
        {
          label: 'Nyförsäljning per användare',
          data: Object.values(namesAndPris),
          backgroundColor: Object.keys(namesAndPris).map(
            (label) => colorMapping[label.split(' ')[0]] || 'rgba(255, 99, 132, 0.2)'
          ),
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1
        },
      ],
    };
    setChartData(chartD);
  }, [JSON.stringify(data)]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as
          | 'top'
          | 'center'
          | 'left'
          | 'right'
          | 'bottom'
          | 'chartArea'
          | _DeepPartialObject<{ [scaleId: string]: number }>
          | undefined,
      },
      title: {
        display: true,
        text: 'Ackumulerat pris per användare',
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        max: data.length,
        beginAtZero: true,
        ticks: {
          stepSize: 0.1, // Ensures each step on y-axis is at least 1 unit
        },
        
      },
    },
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ width: '80%', maxWidth: '600px' }}>{chartData && <Bar data={chartData} options={options} />}</div>
    </div>
  );
};

const NyförsäljningAvg = ({ data }: { data: SharePointItem[] }) => {
  const [chartData, setChartData] = useState<any>();

  const colorMapping: { [key: string]: string } = {
    "Henrik Magnusson": 'rgba(195, 50, 50, 0.2)',
    "Mutaz Nafel": 'rgba(153, 102, 255, 0.2)',
  };

  useEffect(() => {
    const namesAndPris = data.reduce((acc: any, item) => {
      const username =
        item.UserPrincipalName === "Mutaz.Nafel@greenlandscaping.se"
          ? "Mutaz Nafel"
          : item.UserPrincipalName === "Henrik.Magnusson@greenlandscaping.se"
          ? "Henrik Magnusson"
          : null;

      if (username) {
        if (item.Godk_x00e4_ntpris) {
          // Om användaren inte finns i acc, sätt upp strukturen för att hålla reda på summa och antal
          if (!acc[username]) {
            acc[username] = { totalPris: 0, count: 0 };
          }

          // Summera pris och öka räknaren för denna användare
          acc[username].totalPris += Number(item.Godk_x00e4_ntpris) || 0;
          acc[username].count += 1;
        }
      }
      return acc;
    }, {});

    // Skapa chart-data med genomsnittligt pris
    const chartD = {
      labels: Object.keys(namesAndPris).map((key) => {
        const avgPris = (namesAndPris[key].totalPris / namesAndPris[key].count).toFixed(2); // Beräkna genomsnittet
        if (key === "null") key = "Ej angiven";
        return `${key} (${avgPris})`;
      }),
      datasets: [
        {
          label: 'Nyförsäljning i genomsnitt per användare',
          data: Object.values(namesAndPris).map(
            (user: any) => (user.totalPris / user.count).toFixed(2)
          ),
          backgroundColor: Object.keys(namesAndPris).map(
            (label) => colorMapping[label.split(' ')[0]] || 'rgba(255, 99, 132, 0.2)'
          ),
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    };

    setChartData(chartD);
  }, [JSON.stringify(data)]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as
          | 'top'
          | 'center'
          | 'left'
          | 'right'
          | 'bottom'
          | 'chartArea'
          | _DeepPartialObject<{ [scaleId: string]: number }>
          | undefined,
      },
      title: {
        display: true,
        text: 'Genomsnittligt pris per användare',
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 0.1,
        },
      },
    },
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ width: '80%', maxWidth: '600px' }}>
        {chartData && <Bar data={chartData} options={options} />}
      </div>
    </div>
  );
};

function StatisticsPage({ data, allItems }: { data: SharePointItem[], allItems: SharePointItem[] }) {
    const [activeTab, setActiveTab] = useState('StatusInfo');

    const tabs = [
        { name: 'Status Info', component: <StatusInfo data={data} />, key: 'StatusInfo' },
        { name: 'Avvikelsetyp', component: <Avvikelsetyp data={data} />, key: 'Avvikelsetyp' },
        { name: 'Rapporterad Av', component: <RapporteradAv data={data} />, key: 'RapporteradAv' },
        { name: 'Avslutad Kategori', component: <AvslutadKategori data={allItems.filter(item => item.Status === "Avslutad")} />, key: 'AvslutadKategori' },
    ];

    const authorizedUsers = ["Henrik.Magnusson@greenlandscaping.se", "nicklas.ferhm@greenlandscaping.se", "Mutaz.Nafel@greenlandscaping.se", "andreas.lofkvist@GLNET.SE", "Lars.Bornesvard@greenlandscaping.se"];
    const isAuthorized = authorizedUsers.includes(sessionStorage.getItem("USERPRINCIPALNAME")!);

    if (isAuthorized) {
        tabs.push(
            { name: 'Nyförsäljning', component: <Nyförsäljning data={data} />, key: 'Nyförsäljning' },
            { name: 'Nyförsäljning Avg', component: <NyförsäljningAvg data={data} />, key: 'NyförsäljningAvg' },
            { name: "Pris på skapade anbud", component: <Pris data={data} />, key: "PrispåSkapadeAnbud"}
        );
    }

    return (
        <div>
            <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                <ul className="flex flex-wrap -mb-px">
                    {tabs.map(tab => (
                        <li key={tab.key} className="me-2">
                            <button
                                onClick={() => setActiveTab(tab.key)}
                                className={`inline-block p-4 ${activeTab === tab.key ? 'text-blue-600 border-b-2 border-blue-600' : 'hover:text-gray-600 hover:border-gray-300'}`}
                            >
                                {tab.name}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="mt-10">
                {tabs.find(tab => tab.key === activeTab)?.component}
            </div>
        </div>
    );
}
export default StatisticsPage;
