type Colors = {
    [key: string]: string; // This specifies that each key is a string, and each value is also a string.
};

export const AvvikelsetypColors : Colors = {
    Vandalisering: "#B4B414",
    Akut: "#B41414",
    Förbättringsförslag: "#14B414",
    Inventering: "#6464B4",
    Övrigt: "#505050"
};

export interface AvvikelsetypColorsType {

    Vandalisering: "#B4B414",
    Akut: "#B41414",
    Förbättringsförslag: "#14B414",
    Inventering: "#6464B4",
    Övrigt: "#505050"
}

export const getColorForAvvikelsetyp = (avvikelsetyp: string | undefined) : string => {
    if (!avvikelsetyp) {
        return AvvikelsetypColors.Övrigt
    }
    switch (avvikelsetyp) {
        case "Förbättringsförslag":
            return AvvikelsetypColors.Förbättringsförslag
        case "Akut":
            return AvvikelsetypColors.Akut
        case "Vandalisering":
            return AvvikelsetypColors.Vandalisering
        case "Övrigt":
            return AvvikelsetypColors.Övrigt
        case "Inventering":
            return AvvikelsetypColors.Inventering
        default:
            return '#EA3FF7';
    }
}