import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

// Define the context state interface
interface AppContextState {
  page: string;
  setPage: React.Dispatch<React.SetStateAction<string>>;
  // Add other state properties here if needed
}

// Create context with default values
const defaultContextState: AppContextState = {
  page: 'Start',
  setPage: () => {},
};

export const PageContext = createContext<AppContextState>(defaultContextState);

// Custom hook for using context
export const usePageContext = () => useContext(PageContext);

// Define the props for AppContextProvider
interface AppContextProviderProps {
  children: ReactNode;
}

// AppContextProvider component
// AppContextProvider component
export const PageContextProvider: React.FC<AppContextProviderProps> = ({ children }) => {
  const [page, setPage] = useState<string>('DisplayJob');

  return <PageContext.Provider value={{ page, setPage }}>{children}</PageContext.Provider>;
};
