import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppContextProvider } from './contexts/context';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './MsalAuthentication/authConfig';
import { UserContextProvider } from './contexts/UserContext';
import { PageContextProvider } from './contexts/PageContext';
import { CurrentItemContextProvider } from './contexts/CurrentItemContext';
import { MapContextProvider } from './contexts/MapContext';
import { AuthenticationProvider, Client } from "@microsoft/microsoft-graph-client";
import { FilterContextProvider } from './contexts/FilterContext';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { userCompanyEnum } from './MsalAuthentication/UserConfig';

const msalInstance = new PublicClientApplication(msalConfig);

export const graphClient = Client.initWithMiddleware({
  authProvider: new class implements AuthenticationProvider {
    public async getAccessToken(): Promise<string> {
      try {
        const account = msalInstance.getAllAccounts()[0];
        if (!account) throw new Error("No user account found");

        const response = await msalInstance.acquireTokenSilent({
          scopes: ["user.read", "Sites.ReadWrite.All"],
          account: account,
        });

        return response.accessToken;
      } catch (err) {
        throw err;
      }
    }
  }()
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const getUserToken = async () => {
    try {

      await msalInstance
        .acquireTokenSilent({
          scopes: ['Group.Read.All', 'GroupMember.Read.All', 'User.Read.All'],
          account: msalInstance.getAllAccounts()[0],
        })
        .then((token) => {
          sessionStorage.setItem('GRAPHTOKEN', token.accessToken);
        });
    } catch (error) {
      console.error('Error setting user:', error);
    }
  };

const queryClient = new QueryClient({defaultOptions:{queries:{
  retry: (failureCount: number, error:any) => {
    if (error?.response?.status === 401) {
       
       return true;
    }
    return failureCount < 5
  },
  }
},
queryCache: new QueryCache({
  onError: async (err) => {
    if (err)
      console.log("ERROR: ",err.message);
    await getUserToken();
    return err;
    // });
}})
});

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <UserContextProvider>
        <QueryClientProvider client={queryClient}>
          <AppContextProvider>
            <PageContextProvider>
              <MapContextProvider>
                <CurrentItemContextProvider>
                  <FilterContextProvider>
                    <App />
                  </FilterContextProvider>
                </CurrentItemContextProvider>
              </MapContextProvider>
            </PageContextProvider>
          </AppContextProvider>
        </QueryClientProvider>
      </UserContextProvider>
    </MsalProvider>
  </React.StrictMode>
);

reportWebVitals();
