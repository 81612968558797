import { userCompanyEnum } from "../MsalAuthentication/UserConfig"

export const apiEndpoints = {
    allItems: "https://greenlandscapingmalmo.sharepoint.com/sites/GLMalmAB-EgenkontrollerGPISSVeloa/_api/web/lists(guid'1eed3852-3fe7-4ef4-b2f0-d8795d36e236')/items?$top=2000",
    sharepointItems: "https://greenlandscapingmalmo.sharepoint.com/sites/GLMalmAB-EgenkontrollerGPISSVeloa/_api/web/lists(guid'1d4eac66-baef-4223-b55a-602f1b602f59')/items",
    powerAutomateEndpoint: "https://prod-83.westeurope.logic.azure.com:443/workflows/8dd112503d4141dbbdc2f35c315b3416/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Dk6UcNA-Z1vtJfTLq-T_FXfLV9LfyI4H4NkHNYpAdIA",
    powerAutomateGet: "https://prod-246.westeurope.logic.azure.com:443/workflows/03230d46d204439393c889f957be6fae/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=nwUrymOeECPoEM04fXpQ2Nl7fqA2H4kCkSoMk1ORl0s",
    patchItem: (ID:string | number) => `https://greenlandscapingmalmo.sharepoint.com/sites/GLMalmAB-EgenkontrollerGPISSVeloa/_api/web/lists(guid'1eed3852-3fe7-4ef4-b2f0-d8795d36e236')/items(${ID})`,
    fields:"https://greenlandscapingmalmo.sharepoint.com/sites/GLMalmAB-EgenkontrollerGPISSVeloa/_api/web/lists(guid'1eed3852-3fe7-4ef4-b2f0-d8795d36e236')/fields?$top=2000",
    inventeringar: "https://greenlandscapingmalmo.sharepoint.com/sites/GLMalmAB-EgenkontrollerGPISSVeloa/_api/web/lists(guid'2950c193-0d4a-42ee-bdee-ec733e0b11ca')/items?$top=2000"
}

export const apiEndpointsMarkserviceSthlm = {
    sharepointItems: "https://greenlandscapingmalmo.sharepoint.com/sites/MarkserviceSthlmDigitalisering/_api/web/lists(guid'601f4916-d5dd-4e67-8f5c-400749f77709')/items",
    fields: "https://greenlandscapingmalmo.sharepoint.com/sites/MarkserviceSthlmDigitalisering/_api/web/lists(guid'601f4916-d5dd-4e67-8f5c-400749f77709')/fields?$top=2000",
    allItems: "https://greenlandscapingmalmo.sharepoint.com/sites/MarkserviceSthlmDigitalisering/_api/web/lists(guid'601f4916-d5dd-4e67-8f5c-400749f77709')/items?$top=2000",
    powerAutomateEndpoint: "https://prod-83.westeurope.logic.azure.com:443/workflows/8dd112503d4141dbbdc2f35c315b3416/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Dk6UcNA-Z1vtJfTLq-T_FXfLV9LfyI4H4NkHNYpAdIA",
    powerAutomateGet: "https://prod-246.westeurope.logic.azure.com:443/workflows/03230d46d204439393c889f957be6fae/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=nwUrymOeECPoEM04fXpQ2Nl7fqA2H4kCkSoMk1ORl0s",
    patchItem: (ID: string | number) => `https://greenlandscapingmalmo.sharepoint.com/sites/MarkserviceSthlmDigitalisering/_api/web/lists(guid'601f4916-d5dd-4e67-8f5c-400749f77709')/items(${ID})`,
    inventeringar: "https://greenlandscapingmalmo.sharepoint.com/sites/GLMalmAB-EgenkontrollerGPISSVeloa/_api/web/lists(guid'2950c193-0d4a-42ee-bdee-ec733e0b11ca')/items?$top=2000"
}


export const getApiEndpoint = () => {

    const userCompany = sessionStorage.getItem("USERCOMPANY");
    console.log("USER COMPANY: ",userCompany)
    if (userCompany === userCompanyEnum.Markservice){
        return apiEndpointsMarkserviceSthlm;
    }
    return apiEndpoints;
}

