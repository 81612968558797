import React, { useContext } from "react";
import Wiridis from './../assets/Wiridis.png'
import Markservice from './../assets/markservice_sthlm.png';
import './TopSegment.tsx.css';
const TopSegment: React.FC = () => {
  return (
    <div className="top-segment-container">
      <div className="left-section">
        <div className="image-container">
          <img
            src={sessionStorage.getItem('USERCOMPANY') === 'MarkserviceSthlm' ? Markservice : Wiridis}
            alt="Wiridis Bil"
            style={{ maxHeight: 160 }}
          />
        </div>
        <div className="title-container">
          <h3>Aktiva ÄTA-Rapporteringar</h3>
        </div>
      </div>
      <div className="right-section w-100 h-100">

      </div>
    </div>
  );
}

export default TopSegment;