import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

// Define the context state interface
interface AppContextState {
  mapInstance: mapboxgl.Map | null;
  setMapInstance: React.Dispatch<React.SetStateAction<mapboxgl.Map | null>>;
}

// Create context with default values
const defaultContextState: AppContextState = {
  mapInstance: null,
  setMapInstance: () => {},
};

export const MapContext = createContext<AppContextState>(defaultContextState);

// Custom hook for using context
export const useMapContext = () => useContext(MapContext);

// Define the props for AppContextProvider
interface AppContextProviderProps {
  children: ReactNode;
}

// AppContextProvider component
// AppContextProvider component
export const MapContextProvider: React.FC<AppContextProviderProps> = ({ children }) => {
  const [mapInstance, setMapInstance] = useState<mapboxgl.Map | null>(null);
  useEffect(() => {
    console.log('INSIDE MAPCONTEXTPROVIDER: ', mapInstance);
  }, [mapInstance]);
  return <MapContext.Provider value={{ mapInstance, setMapInstance }}>{children}</MapContext.Provider>;
};
