import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGreaterThan } from '@fortawesome/free-solid-svg-icons';
import mapboxgl from 'mapbox-gl';
import ExcelImage from '../assets/Excel.png';
import { Inventering, SharePointAttachment, SharePointItem, apiClient, getAttachments, getInventeringar } from '../Functions/ApiRequests';
import { useUserContext } from '../contexts/UserContext';
import * as ExcelJS from 'exceljs';
import FilterOutlined from '@ant-design/icons/FilterOutlined';
import FilterFilled from '@ant-design/icons/FilterFilled';
import { getColorForAvvikelsetyp } from './Colors';
import { filterDefaults, useFilters } from '../contexts/FilterContext';
import FilterComponent from './FilterComponent';

import { apiEndpoints, getApiEndpoint } from '../constants/Endpoints';
import axios from 'axios';
import { message } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../constants/QueryKeys';

function searchInValues(jsonObject: any, searchText: any) {
  return Object.values(jsonObject).some((value: any) => {
    if (value === undefined || value === null) {
      value = '';
    }
    return value.toString().toLowerCase().includes(searchText.toLowerCase());
  });
}

interface ExportToExcelProps {
  displayedItems: SharePointItem[] | Inventering[];
  attachments: SharePointAttachment[];
  selectedItemType: string;
  accessToken?: string;
}
const indexToColumnLetter = (index: number) => {
  let letter = '',
    temp;
  while (index >= 0) {
    temp = index % 26;
    letter = String.fromCharCode(temp + 65) + letter;
    index = Math.floor(index / 26) - 1;
  }
  return letter;
};

const ExportToExcel: React.FC<ExportToExcelProps> = ({ displayedItems, attachments, selectedItemType }:ExportToExcelProps) => {
  const { user } = useUserContext();
  const ExcelExporter = async () => {
    await axios
      .post(
        getApiEndpoint().powerAutomateEndpoint,
        {
          Items: displayedItems,
          UserCompany: sessionStorage.getItem('USERCOMPANY'),
          CurrentUser: sessionStorage.getItem('CURRENTUSER'),
          CurrentUserPersonalName: sessionStorage.getItem('PERSONALNAME'),
          selectedItemType: selectedItemType

        }
      )
      .then((data) => {
        message.success("Protokollet över valda ÄTA-arbeten skickas på mail.. Detta kan dröja upp till flera minuter.")
        return data;
      }).catch(() => message.error("Misslyckades att skapa ÄTA-protokollet."));
  }

  return (
    <div style={{ width: '30%', height: '100%' }}>
      <img
        title="Exportera visade objekt till Excel"
        src={ExcelImage}
          style={{ width: 30, cursor: 'pointer', marginTop: 6, marginLeft: 6 }}
        onClick={ExcelExporter}
      />
    </div>
  );
};

interface ItemsGalleryProps {
  currentItem: SharePointItem | Inventering | null;
  setCurrentItem: React.Dispatch<React.SetStateAction<SharePointItem | Inventering | null>>;
  allItems: SharePointItem[];
  setAllItems: React.Dispatch<React.SetStateAction<SharePointItem[]>>;
  displayedItems: SharePointItem[];
  setDisplayedItems: React.Dispatch<React.SetStateAction<SharePointItem[] >>;
  mapInstance: mapboxgl.Map | null;
  attachments: SharePointAttachment[];
  inventeringar: Inventering[];
  displayedInventeringar:Inventering[];
  setInventeringar: React.Dispatch<React.SetStateAction<Inventering[]>>;
  selectedItemType: 'ÄTA' | 'Inventeringar';
  setSelectedItemType: React.Dispatch<React.SetStateAction<'ÄTA' | 'Inventeringar'>>;
}

const ItemsGallery: React.FC<ItemsGalleryProps> = ({
  currentItem,
  setCurrentItem,
  allItems,
  displayedItems,
  setDisplayedItems,
  mapInstance,
  attachments,
  setInventeringar,
  inventeringar,
  setSelectedItemType,
  selectedItemType,
  displayedInventeringar
  
}:ItemsGalleryProps) => {

  const {filters, setFilters} = useFilters();
  const [showFilters,setShowFilters] = useState<boolean>(false);
  
  const itemRefs = useRef<{ [key: string]: HTMLDivElement }>({});

  useQuery({
    queryFn:() => getInventeringar(null, user.GraphToken!).then(d => {
      setInventeringar(d)
      return d;
    }), 
    queryKey:[QueryKeys.Inventeringar], 
    enabled: selectedItemType ==="Inventeringar"})


  useEffect(() => {
    if (currentItem && itemRefs.current[currentItem.ID]) {
      itemRefs.current[currentItem.ID].scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'end',
      });
    }
  }, [currentItem, displayedItems]); 

  const { user } = useUserContext();
  
  return (
    <>
      <div style={{ height: '100%', width: '100%' }}>
        <div
          style={{
            display: 'flex',
            borderBottom: '2px solid #ccc',
            height: '5vh',
            marginBottom: '4px',
            width: '100%',
            textAlign: 'left',
            flexDirection: 'row',
          }}>
          <input
            title="Search Input"
            id="SearchTextInput"
            name="SearchTextInput"
            style={{ width: '100%', height: '100%', border: '1px solid black', borderRadius: '5px' }}
            onChange={(e) => {
              setFilters({
                ...filters,
                search: (item) => searchInValues(item, e.target.value),
                counter: filters?.counter! + 1,
              });
            }}
          />




          {user.GraphToken && (
            <div style={{ height: '100%', width: '100%', flexDirection: 'row', display: 'flex' }}>
              <ExportToExcel displayedItems={selectedItemType === "ÄTA" ? displayedItems : displayedInventeringar} attachments={attachments} accessToken={user.GraphToken} selectedItemType={selectedItemType}/>
              <FilterMenu
                setDisplayedItems={setDisplayedItems}
                allItems={allItems}
                displayedItems={displayedItems}
                setShowFilters={setShowFilters}
                showFilters={showFilters}
              />
            </div>
          )}
        </div>
        <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
    <ul className="flex flex-wrap -mb-px">
        <li className="me-2">
            <a onClick={() => {
              if(selectedItemType !== "ÄTA"){
                setSelectedItemType('ÄTA');
                setFilters(filterDefaults.filters);
              }
              
            }} className={`inline-block cursor-pointer p-4 text-black border-b-2 ${selectedItemType === 'ÄTA' ? 'border-blue-400 bg-slate-50' : "border-transparent"} rounded-t-lg hover:text-gray-600 hover:bg-slate-50 hover:border-gray-300 dark:hover:text-gray-300`}>ÄTA</a>
        </li>
        <li className="me-2">
            <a onClick={() => {
              if(selectedItemType !== "Inventeringar"){
                setSelectedItemType('Inventeringar');
                setFilters(filterDefaults.filters);
              }
            }} className={`inline-block cursor-pointer p-4 text-black border-b-2 ${selectedItemType === "Inventeringar" ? 'border-blue-400 bg-slate-50' : "border-transparent"} rounded-t-lg active hover:bg-slate-50 hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300`}>Inventeringar</a>
        </li>
        
    </ul>
</div>
        <div>
        <div className="flex flex-row">
          <div className={`border-2 mr-3 rounded-full bg-red w-5 h-5 ${'bg-red-600'}`}></div>{' '}
          <small className="mr-1">Anbud ej påbörjat</small>
          <div className={`border-2 mr-3 rounded-full bg-red w-5 h-5 ${'bg-yellow-600'}`}></div>{' '}
          <small className="mr-1">Anbudskalkyl skapad</small>
          <div className={`border-2 mr-3 rounded-full bg-red w-5 h-5 ${'bg-green-600'}`}></div>{' '}
          <small className="mr-1">Anbudsdokument skapat</small>
        </div>
        </div>
        {selectedItemType === "ÄTA" ?  
        <div className="gallery-scroll" style={{ height: '74vh', overflow: 'auto' }}>
          {displayedItems
            .sort( (a:SharePointItem,b:SharePointItem) => b.ID - a.ID)
            .map((item: any) => {
              let backgroundColor = '#efefef';
              if (currentItem && currentItem !== undefined && item.id === currentItem.ID) backgroundColor = '#aaaaaa';
              if (item)
                return (
                  <div
                    ref={(el) => {
                      if (el) itemRefs.current[item.ID] = el;
                    }}
                    key={'div-DataCardItem-' + item.ID}>
                    <DataCard
                      allItems={allItems}
                      mapInstance={mapInstance}
                      item={item}
                      key={'DataCardItem' + item.ID}
                      currentItem={currentItem}
                      setCurrentItem={setCurrentItem}
                      attachments={attachments.find((a) => a.id === currentItem?.ID)}
                    />
                  </div>
                );
            })}
        </div>
        :
        <div className="gallery-scroll" style={{ height: '74vh', overflow: 'auto' }}>
          {displayedInventeringar
            .sort( (a:Inventering,b:Inventering) => b.ID - a.ID)
            .map((item: any) => {
              let backgroundColor = '#efefef';
              if (currentItem && currentItem !== undefined && item.id === currentItem.ID) backgroundColor = '#aaaaaa';
              if (item)
                return (
                  <div
                    ref={(el) => {
                      if (el) itemRefs.current[item.ID] = el;
                    }}
                    key={'div-DataCardItem-' + item.ID}>
                    <DataCardInventeringar
                      allItems={allItems}
                      mapInstance={mapInstance}
                      item={item}
                      key={'DataCardItem' + item.ID}
                      currentItem={currentItem as Inventering}
                      setCurrentItem={setCurrentItem}
                      attachments={attachments.find((a) => a.id === currentItem?.ID)}
                    />
                  </div>
                );
            })}
        </div>
        }
        {showFilters && <FilterComponent allItems={allItems} setShowFilters={setShowFilters} inventeringar={inventeringar} selectedItemType={selectedItemType}/>}
      </div>
    </>
  );
};
export default ItemsGallery;

const FilterMenu = ({
  showFilters,
  setShowFilters,
  setDisplayedItems,
  allItems,
  displayedItems
}: {
  setDisplayedItems: React.Dispatch<React.SetStateAction<SharePointItem[]>>;
  allItems: SharePointItem[];
  displayedItems: SharePointItem[];
  showFilters: boolean;
  setShowFilters: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <div style={{ width: '70%', height: '100%', textAlign:'end', justifyItems:'center', alignItems:'center'}}>

      {showFilters ? (
        <FilterFilled
          onClick={() => {
            setShowFilters(!showFilters);
          }}
          style={{ fontSize: '40px', color: 'green', float: 'inline-end' }}
        />
      ) : (
        <FilterOutlined
          onClick={() => {
            setShowFilters(!showFilters);
          }}
          style={{ fontSize: '40px', color: 'green', float: 'inline-end' }}
        />
      )}
    </div>
  );
};

interface DataCardProps {
  item: SharePointItem;
  allItems: SharePointItem[];
  currentItem: SharePointItem | Inventering | null;
  setCurrentItem: React.Dispatch<React.SetStateAction<SharePointItem | Inventering | null>>;
  mapInstance: mapboxgl.Map | null;
  attachments: SharePointAttachment | undefined;
}

const DataCard: React.FC<DataCardProps> = ({
  mapInstance,
  item,
  currentItem,
  setCurrentItem,
  allItems,
  attachments,
}) => {
  const { user } = useUserContext();

  async function onSelect(item: SharePointItem) {
    getAttachments(item, user?.GraphToken || '');
    setCurrentItem(item);
  }
  async function onSelectArrow(item: SharePointItem | Inventering) {
    const images = await getAttachments(item, user?.GraphToken || '');
    setCurrentItem({ ...item, Images: images });
    let lnglat: [number, number] = [Number(item.Longitud), Number(item.Latitud)];
    try {
      mapInstance && mapInstance.flyTo({ center: lnglat, minZoom: 10 });
    } catch {
      console.log('Error with Flyto, Coordinates are not accurate');
    }
  }

  let backgroundColor = '#efefef';
  if (currentItem && currentItem !== undefined && item.ID === currentItem.ID) backgroundColor = '#aaaaaa';
  return (
    <div
      style={{
        border: '1px solid #ddd',
        borderLeft: '2px solid #ddd',
        overflow: 'clip',
        backgroundColor: backgroundColor,
        height: '10vh',
        position: 'relative',
        userSelect: 'none',
        cursor: 'pointer',
      }}
      key={item.ID}
      onClick={() => onSelect(item)}>
      <div style={{ marginTop: '1vh', marginLeft: '1vw', position: 'absolute' }}>{item.Namn}</div>
      <div
        style={{
          marginTop: '4vh',
          marginLeft: '1vw',
          position: 'absolute',
          fontSize: '1.5vh',
          width: '20vw',
          height: '4vh',
          textAlign: 'left',
        }}>
        {item.ID}. {item.Title}
      </div>
      <div
        style={{
          marginLeft: '1vw',
          paddingLeft: '20px',
          paddingRight: '20px',
          marginBottom: '10px',
          position: 'absolute',
          fontSize: '1.5vh',
          fontWeight: 'bold',
          width: 'auto',
          height: '2vh',
          backgroundColor: 'white',
          borderRadius: '30px',
          color: getColorForAvvikelsetyp(item.Avvikelsetyp),
          bottom: 0,
        }}>
        {item.Avvikelsetyp}
      </div>
      <div className={`border-2 rounded-full bg-red w-5 h-5 ${item.AnbudSkapat ? "bg-green-600" : item.Anbudskalkyll ? "bg-yellow-600":"bg-red-600"}`}></div>

      <FontAwesomeIcon
        onClick={() => onSelectArrow(item)}
        icon={faGreaterThan}
        style={{
          color: '#006649',
          float: 'right',
          marginRight: '1.5vw',
          marginTop: '3vh',
          fontSize: '4vh',
          cursor: 'pointer', 
        }}
      />
    </div>
  );
};

interface DataCardInventeringarProps {
  item: Inventering;
  allItems: SharePointItem[];
  currentItem: Inventering | null;
  setCurrentItem: React.Dispatch<React.SetStateAction<SharePointItem | Inventering | null>>;
  mapInstance: mapboxgl.Map | null;
  attachments: SharePointAttachment | undefined;
}

const DataCardInventeringar: React.FC<DataCardInventeringarProps> = ({
  mapInstance,
  item,
  currentItem,
  setCurrentItem,
  allItems,
  attachments,
}) => {
  const { user } = useUserContext();

  async function onSelect(item: Inventering | SharePointItem) {
    getAttachments(item, user?.GraphToken || '');
    setCurrentItem(item);
  }

  async function onSelectArrow(item: Inventering) {
    const images = await getAttachments(item, user?.GraphToken || '');
    let lnglat: [number, number] = [Number(item.Longitud), Number(item.Latitud)];
    try {
      mapInstance && mapInstance.flyTo({ center: lnglat, minZoom: 10 });
    } catch {
      console.log('Error with Flyto, Coordinates are not accurate');
    }
  }

  let backgroundColor = '#efefef';
  if (currentItem && currentItem !== undefined && item.ID === currentItem.ID) backgroundColor = '#aaaaaa';
  return (
    <div
      style={{
        border: '1px solid #ddd',
        borderLeft: '2px solid #ddd',
        overflow: 'clip',
        backgroundColor: backgroundColor,
        height: '10vh',
        position: 'relative',
        userSelect: 'none',
        cursor: 'pointer',
      }}
      key={item.ID}
      onClick={() => onSelect(item)}>
      <div style={{ marginTop: '1vh', marginLeft: '1vw', position: 'absolute' }}>{item.Title}</div>
      <div
        style={{
          marginTop: '4vh',
          marginLeft: '1vw',
          position: 'absolute',
          fontSize: '1.5vh',
          width: '20vw',
          height: '4vh',
          textAlign: 'left',
        }}>
        {item.ID}. {item.Title}
      </div>
      <div
        style={{
          marginLeft: '1vw',
          paddingLeft: '20px',
          paddingRight: '20px',
          marginBottom: '10px',
          position: 'absolute',
          fontSize: '1.5vh',
          fontWeight: 'bold',
          width: 'auto',
          height: '2vh',
          backgroundColor: 'white',
          borderRadius: '30px',
          color: "chucknorris",
          bottom: 0,
        }}>
        {item.Kategori}
      </div>

      <FontAwesomeIcon
        onClick={() => onSelectArrow(item)}
        icon={faGreaterThan}
        style={{
          color: '#006649',
          float: 'right',
          marginRight: '1.5vw',
          marginTop: '3vh',
          fontSize: '4vh',
          cursor: 'pointer',
        }}
      />
    </div>
  );
};



