import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
export function DrawOnMap(map, onChange){
    const draw = new MapboxDraw({
        displayControlsDefault:false,
        controls:{
            polygon:true,
            trash:true
        }
    });
    map.addControl(draw, 'top-left');
    map.on('draw.create', (e) => {
            if (onChange){
                onChange(draw.getAll());
            }
    });
    map.on('draw.update', (e) => {
            if (onChange){
                onChange(draw.getAll());
            }
    });
    map.on('draw.delete', (e) => {
            if (onChange){
                onChange(draw.getAll());
            }
    });
    return {draw,
        getPolygons: () => {
            const data = draw.getAll();
            return data.features.filter(feature => feature.geometry.type === 'Polygon');
        }
    };
}