import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { json } from 'stream/consumers';

export interface ILocalization {
    MonthScheduleHeader: string;
    DirectScheduleHeader: string;
    WeekScheduleHeader: string;
    January: string;
    February: string;
    March: string;
    April: string;
    May: string;
    June: string;
    July: string;
    August: string;
    September: string;
    October: string;
    November: string;
    December: string;
    Finished: string;
    NotFinished: string;
    Period: string;
    Controlled: string;
    Comment: string;
    Deviation: string;
    Images: string;
    No: string;
    Yes: string;
    Save: string;
    notStandaloneAppError:string;
    notLoggedInMessage:string;
}
function getLanguageCode(value: string): string {
    switch (value.toLowerCase()) {
        case 'sv':
            return '/Languages/sv.json'; // Swedish
        case 'en':
            return '/Languages/en.json'; // English
        case 'de':
            return '/Languages/de.json'; // German
        case 'lt':
            return '/Languages/lt.json'; // Lithuanian
        case 'no':
            return '/Languages/no.json'; // Norwegian
        case 'fi':
            return '/Languages/fi.json'; // Finnish
        default:
            return '/Languages/en.json'; // Default language code (English)
    }
}

export const defaultLocalization: ILocalization = {
    MonthScheduleHeader: "Monthly",
    DirectScheduleHeader: "Direct",
    WeekScheduleHeader: "Weekly",
    January: "January",
    February: "February",
    March: "March",
    April: "April",
    May: "May",
    June: "June",
    July: "July",
    August: "August",
    September: "September",
    October: "October",
    November: "November",
    December: "December",
    Finished: "Finished",
    NotFinished: "Not Finished",
    Period: "Period",
    Controlled: "Controlled",
    Comment: "Comment",
    Deviation: "Deviation",
    Images: "Images",
    No: "No",
    Yes: "Yes",
    Save: "Save",
    notStandaloneAppError:"App is not downloaded! You need to download the app to use it.",
    notLoggedInMessage:"Log in please."
};

export const getLocalization = async (lang:string): Promise<ILocalization> => {


        const pr = fetch(getLanguageCode(lang))
            .then(response => response.json())
            .then(data => {console.log(data);return data as ILocalization})
            .catch(error => fetch('/Languages/en.json').then((rs:any) => rs.json()).catch((t: any) => console.log("Error getting language.")));

    return pr;
};


