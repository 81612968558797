import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { UserType } from '../MsalAuthentication/UserConfig';
interface AppContextState {
  user:UserType;
  setUser:React.Dispatch<React.SetStateAction<any>>;
}
const defaultUser : UserType = {
  groups: undefined,
  userId: undefined,
  name:undefined,
  UPN: undefined,
  GraphToken:undefined,
}

// Create context with default values
const defaultContextState: AppContextState = {
    user: defaultUser,
    setUser: () => {},
  };
  
export const UserContext = createContext<AppContextState>(defaultContextState);

// Custom hook for using context
export const useUserContext = () => useContext(UserContext);

// Define the props for AppContextProvider
interface AppContextProviderProps {
  children: ReactNode;
}

export const UserContextProvider: React.FC<AppContextProviderProps> = ({ children }) => {

  const [user, setUser] = useState<UserType>(defaultUser);


  return (
    <UserContext.Provider value={{user, setUser}}>
      {children}
    </UserContext.Provider>
  );
};
