import React, { useEffect, useRef, useState } from 'react';
import mapboxgl, { Control, MapboxGeoJSONFeature, PointLike } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import './mapStyling.css';
import { mapSettings } from './mapSettings';
import Map from 'react-map-gl';
import { Inventering, SharePointItem } from '../../Functions/ApiRequests';
import MapFeatures from './mapComponents/Features';
import DrawControl, { DrawPolygonMode } from 'react-map-gl-draw';
import {DrawOnMap} from './MapDraw';
import { useFilters } from '../../contexts/FilterContext';


interface GeoJsonFeatureCollection {
    type: "FeatureCollection";
    features: GeoJsonFeature[];
}

interface GeoJsonFeature {
    id: string;
    type: "Feature";
    properties: { [key: string]: any };
    geometry: GeoJsonGeometry;
}

interface GeoJsonGeometry {
    type: "Polygon";
    coordinates: number[][][];  // Array of rings, each with an array of coordinates
}


interface MapProps {
  mapInstance: mapboxgl.Map | null;
  setMapInstance: React.Dispatch<React.SetStateAction<mapboxgl.Map | null>>;
  currentItem: SharePointItem | Inventering | null;
  setCurrentItem: React.Dispatch<React.SetStateAction<SharePointItem | Inventering | null>>;
  displayedItems: SharePointItem[];
  setDisplayedItems: React.Dispatch<React.SetStateAction<SharePointItem[]>>;
  inventeringar: Inventering[],
  selectedItemType: 'ÄTA' | 'Inventeringar'
}

const AdditionalWorkMap: React.FC<MapProps> = ({
  mapInstance,
  setMapInstance,
  currentItem,
  displayedItems,
  setDisplayedItems,
  setCurrentItem,
  inventeringar,
  selectedItemType
}) => {
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ? process.env.REACT_APP_MAPBOX_ACCESS_TOKEN : '.';
  const [viewState, setViewState] = useState({
    longitude: -100,
    latitude: 40,
    zoom: 3.5,
  });
  const [hoveredFeature, setHoveredFeature] = useState<MapboxGeoJSONFeature | null>(null);
  const [lastHoveredFeature, setLastHoveredFeature] = useState<MapboxGeoJSONFeature | null>(null);
  const { filters, setFilters } = useFilters();
  const mapRef = useRef(null);
  useEffect(() => {
    if (hoveredFeature) {
      mapInstance?.setFeatureState({ source: hoveredFeature.source, id: hoveredFeature.id }, { circleSize: 16 });
      setLastHoveredFeature(hoveredFeature);
    }

    return () => {
      if (lastHoveredFeature) {
        mapInstance?.setFeatureState(lastHoveredFeature, { circleSize: 12 });
      }
    };
  }, [hoveredFeature?.id]);

  useEffect(() => {
    if (currentItem && mapInstance) {
      mapInstance?.setFeatureState(
        { source: mapSettings.uniqueSourcePrefix, id: String(currentItem.ID) },
        { selectedCircleStrokeSize: 6 }
      );
      mapInstance?.flyTo({ center: [Number(currentItem.Longitud), Number(currentItem.Latitud)] });
    }

    return () => {
      if (currentItem && mapInstance) {
        mapInstance?.setFeatureState(
          { source: mapSettings.uniqueSourcePrefix, id: String(currentItem.ID) },
          { selectedCircleStrokeSize: 1 }
        );
      }
    };
  }, [currentItem?.ID]);

  const handleOnLoad = (e: mapboxgl.MapboxEvent) => {
    let map = e.target;
    setMapInstance(map);
    const drawing = (DrawOnMap as unknown as (map:unknown, fn:unknown) => unknown)(map, 
      (items:GeoJsonFeatureCollection) => {
        console.log(items);
        if (items?.features && items.features.length > 0){
        setFilters(prev => ({...prev, drawMap: (feature) => {
          const coords: [number,number] = [Number(feature.Longitud), Number(feature.Latitud)]
          return isCoordinateWithinPolygons(coords,items);
        }, counter:prev.counter+1}))
        }
        else if (!items?.features || items?.features?.length == 0) {
          setFilters((prev) => ({
            ...prev,
            drawMap: (feature) => {
              return true;
            },
            counter: prev.counter + 1,
          }));
        }
      }
    ) as {draw:Control, getPolygons: () => unknown};
    map.addControl(new mapboxgl.NavigationControl(), 'top-right');
  };

  const handleOnMouseMove = (e: mapboxgl.MapLayerMouseEvent) => {
    const bbox = [
      [e.point.x - 10, e.point.y - 10],
      [e.point.x + 10, e.point.y + 10],
    ] as [PointLike, PointLike];
    if (mapInstance) {
      const features = mapInstance.queryRenderedFeatures(bbox);
      const filteredFeatures = features.filter((f) => {
        return f.properties?.markerColor;
      });
      const feature = filteredFeatures.find((e) => e);

      if (feature) {
        setHoveredFeature(feature);
      } else {
        setHoveredFeature(null);
      }
    }
  };

  const handleOnClick = (e: mapboxgl.MapLayerMouseEvent, activeItems:any[]) => {
    const bbox = [
      [e.point.x - 5, e.point.y - 5],
      [e.point.x + 5, e.point.y + 5],
    ] as [PointLike, PointLike];
    if (mapInstance) {
      const features = mapInstance.queryRenderedFeatures(bbox);
      const filteredFeatures = features.filter((f) => {
        return f.properties?.markerColor;
      });
      const feature = filteredFeatures.find((e) => e);
      let clickedItem = activeItems.find((item) => {
        let res = String(item.ID) === String(feature?.properties?.ID);
        return res;
      });
      clickedItem && setCurrentItem(clickedItem);
    }
  };

  return (
    <Map
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      ref={mapRef}
      initialViewState={{
        longitude: mapSettings.defaultMapCenter[0],
        latitude: mapSettings.defaultMapCenter[1],
        zoom: mapSettings.defaultZoom,
      }}
      style={{ width: '100%', height: '100%' }}
      mapStyle={mapSettings.backgroundMap}
      onMove={(evt) => setViewState(evt.viewState)}
      onMouseMove={handleOnMouseMove}
      onLoad={handleOnLoad}
      onClick={(e) => handleOnClick(e, selectedItemType === 'Inventeringar' ? inventeringar : displayedItems)}>
      {mapInstance && <MapFeatures displayedItems={selectedItemType === 'Inventeringar' ? inventeringar : displayedItems} />}
    </Map>
  );
};

function isPointInPolygon(point: [number, number], polygon: number[][][]): boolean {
  const [px, py] = point;
  const [vertices] = polygon; // Assuming the first ring of the polygon

  let inside = false;
  for (let i = 0, j = vertices.length - 1; i < vertices.length; j = i++) {
    const [xi, yi] = vertices[i];
    const [xj, yj] = vertices[j];

    const intersect = yi > py !== yj > py && px < ((xj - xi) * (py - yi)) / (yj - yi) + xi;

    if (intersect) {
      inside = !inside;
    }
  }

  return inside;
}

function isCoordinateWithinPolygons(coordinate: [number, number], polygons: GeoJsonFeatureCollection): boolean {
  for (const feature of polygons.features) {
    if (feature.geometry.type === 'Polygon') {
      const isInside = isPointInPolygon(coordinate, feature.geometry.coordinates);
      if (isInside) {
        return true;
      }
    }
  }
  return false;
}

export default AdditionalWorkMap;
