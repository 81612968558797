const theme = {
  colors: {
    White: "#FFF",
    Grey1: "#FAFAFA",
    Grey2: "#F5F5F5",
    Grey4: "#D9D9D9",
    Grey7: "#595959",
    Grey9: "#262626",
    PrimaryBlack: "#000",
    PrimaryGray: "#EAEAEA",
    AudienceGrey2: "#65A89D",
    AudienceYellow1: "#FFA400",
    PrimaryRed: "#FB5A55",
    PrimaryGreen: "#006649"
  },
};

export const mapSettings =  {
    backgroundMap : "mapbox://styles/mapbox/streets-v12",
    uniqueSourcePrefix : "Green-Source",
    defaultZoom : 9,
  defaultMapCenter: sessionStorage.getItem("USERCOMPANY") === "MarkserviceSthlm" ? [18.1180, 59.2409] : [13.0428, 55.4895], 
      clustersId : 'clusters',
      clusterCountId : 'cluster-count',
      allItemsPaint:{
        'circle-radius': 8, 
        'circle-color': theme.colors.AudienceYellow1,
        'circle-stroke-width': 1, 
        'circle-stroke-color': theme.colors.White,
        'circle-opacity': 0.75, 
        'circle-stroke-opacity': 0.9
      },
      
    userLocationColor:theme.colors.AudienceGrey2,

     // Colors and steps for the clusters. Before 10 hidden features, the color is clusterColor1, after clusterColor2, after 50 clusterColor3.
    clusterColor1:theme.colors.Grey2,                 
    clusterColor2:theme.colors.AudienceGrey2,         
    clusterColor3:theme.colors.PrimaryBlack,          
    clusterStep1: 10,
    clusterStep2: 50,
    
}