import React, { useEffect, useState } from 'react';
import { Source, Layer } from 'react-map-gl';
import { Inventering, SharePointItem } from '../../../Functions/ApiRequests';
import { mapSettings } from '../mapSettings';
import { getColorForAvvikelsetyp } from '../../Colors';

export interface EquipmentGeoJsonType {
  type: 'FeatureCollection';
  features: EquipmentFeature[];
}

export interface EquipmentFeature {
  type: 'Feature';
  geometry: EquipmentGeometry;
  properties: SharePointItem ;
}

export interface EquipmentGeometry {
  type: 'Point';
  coordinates: [number, number];
}

interface FeaturesProps {
  displayedItems: SharePointItem[] |Inventering[]; 
}

const convertToGeoJSON = (items: any[]): EquipmentGeoJsonType => {
  return {
    type: 'FeatureCollection',
    features: items.map((item) => {
      return {
        id:String(item.ID),
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [Number(item.Longitud), Number(item.Latitud)],
        },
        properties: { ...item, markerColor: item.Avvikelsetyp ? getColorForAvvikelsetyp(item.Avvikelsetyp) : "#40ffff"},
      };
    }),
  };
};

const MapFeatures = ({ displayedItems }: FeaturesProps) => {
  const [items, setItems] = useState<EquipmentGeoJsonType>();
  useEffect(() => {
    setItems(convertToGeoJSON(displayedItems));
  }, [displayedItems.toLocaleString()]);

  return (
    <Source
      id={mapSettings.uniqueSourcePrefix}
      type="geojson"
      data={items}
      cluster={true}
      clusterMaxZoom={12}
      clusterRadius={50}
      clusterProperties={{
        max: ['max', 0, ['get', 'scalerank']],
        sum: ['+', 0, ['get', 'scalerank']],
        has_island: ['any', false, ['==', ['get', 'featureclass'], 'island']],
      }}>
      <Layer
        id={`${mapSettings.uniqueSourcePrefix}-circle`}
        type="circle"
        paint={{
          'circle-radius': ['coalesce', ['feature-state', 'circleSize'], 12],
          'circle-color': ['get', 'markerColor'],
          'circle-stroke-width': ['coalesce', ['feature-state', 'selectedCircleStrokeSize'], 1],
          'circle-stroke-color': '#303030',

          'circle-opacity': 0.75,
          'circle-stroke-opacity': 0.9,
        }}
      />

      <Layer
        id={'Clusters'}
        type="circle"
        source={mapSettings.uniqueSourcePrefix}
        filter={['has', 'point_count']}
        paint={{
          'circle-color': ['step', ['get', 'point_count'], '#204020', 8, '#AA20AA', 20, '#FaAA20'],
          'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
        }}
      />
      <Layer
        id={'Clusters-Count'}
        type="symbol"
        source={mapSettings.uniqueSourcePrefix}
        filter={['has', 'point_count']}
        layout={{
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        }}
      />
    </Source>
  );
};

export default MapFeatures;